<template>
  <div>
    <div v-if="isArea" class="card rounded-lg addArea general-shadow" style="border: none; border-radius: 8px;">
        <img v-if="data.photoUrl"
          class="image__img card rounded-lg general-shadow"
          :src="data.photoUrl"
          style="border-radius: 8px;"
          >
        <div v-else class="col-6 pt-2 text-center">
          <i class="icon general-blue-color" :class="data.socialAreaType.icon" style="font-size: 60px"></i>
        </div>
        <div class="image__overlay card rounded-lg" style="border-radius: 8px;">
            <div class="image__title">{{data.name}}</div>
            <p class="image__description">{{$t('areas.thumbnailText')}}</p>
        </div>
    </div>
    <div v-else class="card mx-auto rounded-lg card-container addArea general-shadow">
        <div class="col-6 pt-2 text-center">
          <i class="plus circle icon color-inactive" style="font-size: 60px"></i>
        </div>
        <div class="image__overlay" style="border-radius: 8px;">
            <!--<p class="image__description">Editar</p>-->
            <div class="image__title">{{$t('areas.buttons.createArea')}}</div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AreaThumbnail',
  components: {
    // EditAreaModal
  },
  props: {
    data: {
      type: Object,
      required: false
    },
    isForNewArea: {
      type: Object,
      required: false
    }
  },
  /*
  data () {
    return {
      isEditing: false
    }
  }, */
  /* mounted () {
    console.log('new AreaThumbnail is mounted and its data is ', this.data)
  }, */
  computed: {
    classObject () {
      return {
        background: `url(${this.data.photoUrl})no-repeat`
      }
    },
    isArea () {
      return Object.keys(this.data).length
    }
  },
  methods: {
    openEditModal () {
      this.isEditing = true
    }
  }
}
</script>

<style scoped>
.image{
    position: relative;
    width: 300px;
    height: 188px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;*/
    /* box-shadow: 0px 5px 10px -2px rgb(0 0 0 / 10%)*/
}

.addArea {
    position: relative;
    width: 300px;
    height: 188px;
    margin-bottom: 10px;
    margin-left: 10px !important;
    margin-right: 10px;
    margin-top: 10px;
    /*box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;*/
    /*box-shadow: 0px 5px 10px -2px rgb(0 0 0 / 10%) !important;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

.image__img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /*border-radius: 8px;*/
}

.image__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    font-family: inherit;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
    /* border-radius: 8px; */
}

.image__overlay > * {
    transform: translateY(20px);
    transition: transform 0.25s
}

.image__overlay:hover {
    opacity: 1;
    cursor: pointer;
}

.image__overlay:hover > * {
    transform: translateY(0);
}

.image__title{
   font-size: 2em;
   font-weight: bold;
   margin-bottom: 10px;
   text-align: center;
}

.image_description{
    font-size: 1.9em;
}

.text-center {
    text-align: center !important;
}
</style>
